@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 50, 145, 55;
    --color-secondary: 0, 0, 0;
    --color-border: 170, 171, 174;
    /* ... */
  }

  .pb-100 {
    padding-bottom: 100%;
  }

  b {
    font-weight: 500;
  }
}

[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  outline: none;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 8px;
  background: rgba(var(--color-secondary), 0.05);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: rgba(var(--color-secondary), 0.1);
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover>.resizer {
    opacity: 1;
  }
}

html .simplebar-scrollbar {
  cursor: pointer;
}

html .simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
}

html .simplebar-scrollbar::before {
  background-color: rgba(var(--color-secondary), 0.3);
}

html .simplebar-scrollbar.simplebar-visible:hover:before {
  opacity: 0.7;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: rgba(var(--color-primary), 1);
  color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: rgba(var(--color-primary), 0.8);
  color: #fff;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: rgba(var(--color-primary), 1);
  color: #fff;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: rgba(var(--color-primary), 0.8);
  color: #fff;
}

html .react-datepicker__navigation {
  top: 8px;
}

html .react-datepicker-popper {
  z-index: 50;
}

.icon-calendar {
  background-image: url("./assets/icon-calendar.svg");
  background-repeat: no-repeat;
  background-position: left 0.75rem center;
  background-size: 1rem;
}